<template>
    <div>
        <ts-page-title
            :title="$t('productProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('productProfile.pageTitle'),
                    href: '/admin/product-profiles'
                },
                {
                    text: $t('edit'),
                    active: true
                }
            ]"
        />

        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <div class="row">
                        <div class="col-md-4">
                            <ProductInfo
                                v-model="model"
                                :validate="errors"
                                ref="product_info"
                                class="tw-w-full tw-border tw-p-4 tw-rounded-lg tw-border-gray-200"
                            />
                        </div>
                        <div class="col-md-4">
                            <VendorList
                                v-model="model"
                                :validate="errors"
                                :full="true"
                                class="tw-w-full tw-h-1/2 tw-border tw-p-4 tw-rounded-lg tw-border-gray-200"
                            />
                            <AddOnItem
                                v-model="model"
                                :validate="errors"
                                class="tw-w-full tw-border tw-p-4 tw-mt-4 tw-rounded-lg tw-border-gray-200"
                            />
                        </div>
                        <div class="col-md-4">
                            <ProductPhoto
                                v-model="model"
                                :validate="errors"
                                class="tw-w-full tw-border tw-p-4 tw-rounded-lg tw-border-gray-200"
                            />
                        </div>
                    </div>
                    <div class="row tw-pt-4">
                        <div class="col-md-12">
                            <ProSaleCodeList
                                v-model="model"
                                :validate="errors"
                            />
                        </div>
                    </div>
                    <div class="row tw-mt-4">
                        <div
                            class="col-md-12 tw-space-x-3 tw-flex tw-justify-end"
                        >
                            <ts-button
                                @click.prevent="
                                    $router.push({ name: 'product-profile' })
                                "
                                >{{ $t('cancel') }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                @click.prevent="onUpdate"
                                :waiting="waiting"
                                >{{ $t('update') }}</ts-button
                            >
                        </div>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import ProductPhoto from './components/product-photo.vue'
import ProductInfo from './components/product-info.vue'
import VendorList from './components/vendor-list'
import ProSaleCodeList from './components/pro-sale-code-list.vue'
import AddOnItem from './components/add-on-item'
import { mapState, mapActions } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'productProfileEdit',
    components: {
        ProductPhoto,
        ProductInfo,
        ProSaleCodeList,
        VendorList,
        AddOnItem
    },
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            model: {
                product_id: 0,
                category_id: null,
                product_name_kh: null,
                product_name_en: null,
                barcode: null,
                uom_cat_id: null,
                product_type_id: 2,
                product_mode_id: 1,
                age_eligible_from: null,
                age_eligible_to: null,
                minimum_reorder_qty: null,
                maximum_reorder_allow: null,
                is_expirable: false,
                is_feature: false,
                vat_eligible: false,
                is_generate_unique_serial: false,
                pro_group_id: null,
                photo: null,
                product_sale_code: [],
                other_branch_list: [],
                vendor_list: [],
                recipes: [],
                account_id: undefined
            }
        }
    },
    computed: {
        ...mapState('product/profile', ['edit_data'])
    },
    methods: {
        ...mapActions('product/profile', [
            'fetchBefore',
            'update',
            'upload',
            'find',
            'formViewModel'
        ]),
        async uploadPhoto (id) {
            if (this.file instanceof File || this.file instanceof Blob) {
                if (id > 0) {
                    let formData = new FormData()
                    formData.append('photo', this.file)
                    await this.upload({
                        id: id,
                        data: formData
                    })
                }
            }
        },
        onUpdate () {
            let { photo, ...dataSend } = this.model
            this.file = photo
            this.waiting = true
            this.errors = new Errors()
            this.update({
                id: this.model.product_id,
                data: dataSend
            })
                .then(async response => {
                    await this.uploadPhoto(response.product_id)
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$router.push({ name: 'product-profile' })
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.product_id = this.edit_data.product_id
                this.model.category_id = this.edit_data.category_id
                this.model.product_name_kh = this.edit_data.product_name_kh
                this.model.product_name_en = this.edit_data.product_name_en
                this.model.barcode = this.edit_data.barcode
                this.model.uom_cat_id = this.edit_data.uom_cat_id
                this.model.product_type_id = this.edit_data.product_type_id
                this.model.product_mode_id = this.edit_data.product_mode_id
                this.model.age_eligible_from = this.edit_data.age_eligible_from
                this.model.age_eligible_to = this.edit_data.age_eligible_to
                this.model.minimum_reorder_qty =
                    this.edit_data.minimum_reorder_qty
                this.model.maximum_reorder_allow =
                    this.edit_data.maximum_reorder_allow
                this.model.is_expirable = this.edit_data.is_expirable
                this.model.is_feature = this.edit_data.is_feature
                this.model.vat_eligible = this.edit_data.vat_eligible
                this.model.is_generate_unique_serial = this.edit_data
                    .is_generate_unique_serial
                    ? this.edit_data.is_generate_unique_serial
                    : false
                this.model.pro_group_id = this.edit_data.pro_group_id
                this.model.photo = this.edit_data.photo
                this.model.product_sale_code = []
                this.model.account_id = this.edit_data.account_id
                this.model.other_branch_list = []
                this.model.vendor_list = this.edit_data.vendors.map(
                    el => el.vendor_id
                )
                this.model.recipes = this.edit_data.product_add_on
                    ? this.edit_data.product_add_on.map(
                          el => el.addon_pro_sale_code_id
                      )
                    : []
                if (this.model.product_type_id != 3) {
                    this.$children[1].$children[0].fetchRecipe()
                }
                if (this.edit_data.account_id) {
                    this.$children[1].$children[0].handleSearch(
                        this.edit_data.account_id
                    )
                }
            }
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PRODUCT',
                desc: not.text
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('product/profile/CLEAR_SETUP')
        this.$store.commit('product/profile/SET_EDIT_DATA', {})
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(async vm => {
            vm.loading = true
            await vm.find(to.params.id)
            await vm
                .fetchBefore({ params: {}, index: undefined })
                .finally(() => {
                    vm.setEditData()
                    vm.loading = false
                })
        })
    }
}
</script>
